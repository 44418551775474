export default {
    DEFAULT_COUNTRY: 'us',
    OPTIONS: [
        {
            name: 'US',
            value: 'us',
            uid: 'ticketmaster_policy_us'
        },
        {
            name: 'Canada',
            value: 'ca',
            uid: 'ticketmaster_policy_us'
        },
        {
            name: 'United Kingdom',
            value: 'uk',
            uid: 'ticketmaster_policy_uk'
        },
        {
            name: 'Mexico',
            value: 'mx',
            url: 'https://help.ticketmaster.com.mx/hc/en-us/articles/24797492287633-Travel-Experience-Policy'
        },
    ]
};