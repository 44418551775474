<template>
    <BaseSlice v-bind="navigationAttributes" :slice-type="slice.slice_type" :slice-index="slice.index" class="gst-slice-simple-content pb-2">
        <PrismicRichText
            v-if="isRichTextNotEmpty( slice.primary.title )"
            :field="slice.primary.title"
            class="gst-slice-simple-content__title" />
        <PrismicRichText :field="slice.primary.content" class="gst-slice-simple-content__content" />
    </BaseSlice>
</template>

<script>
    import NavMixin from '@tenants/ticketmaster/app/slices/mixins/NavMixin.js';
    import FieldMixin from '@tenants/ticketmaster/app/slices/mixins/FieldMixin.js';
    import BaseSlice from '@tenants/ticketmaster/app/slices/components/BaseSlice.vue';

    export default {
        name: 'SimpleContent',
        components: {
            BaseSlice
        },
        mixins: [
            NavMixin,
            FieldMixin
        ],
        props: {
            slice: {
                type: Object,
                default: null
            },
        }
    };
</script>
