var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":"","content-class":"gst-drop-down__menu","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"gst-drop-down d-inline-block"},on),[_c('span',{staticClass:"u-mouse-pointer",class:{
                    'tertiary--text': !_vm.menuOpened,
                    'primary--text': _vm.menuOpened
                },attrs:{"data-test-id":_vm.$attrs['data-test-id']}},[_vm._v(" "+_vm._s(_vm.value ? _vm.renderItemFn( _vm.value ) : _vm.$t('_common:terms.all'))+" ")]),_c('i',{staticClass:"u-mouse-pointer gst-icon-svg",class:{
                    'gst-icon-svg--active u-rotate-180': _vm.menuOpened
                }},[_c('BaseIcon',{staticClass:"gst-dropdown-icon-svg",attrs:{"symbol-id":"icons--dropdown_arrow"}})],1)])]}}]),model:{value:(_vm.menuOpened),callback:function ($$v) {_vm.menuOpened=$$v},expression:"menuOpened"}},[_c('v-list',{staticClass:"gst-drop-down__list"},[(_vm.clearable)?_c('v-list-item',{staticClass:"gst-drop-down__list-item gst-drop-down__reset",attrs:{"disabled":!_vm.value},on:{"click":function($event){return _vm.select( null )}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('_common:terms.reset'))+" ")])],1):_vm._e(),(_vm.clearable)?_c('v-divider'):_vm._e(),(_vm.i18nKeyAllItemsItem)?_c('v-list-item',{staticClass:"gst-drop-down__list-item gst-drop-down__all-items",class:{ 'selected': !_vm.value },on:{"click":function($event){return _vm.select( null )}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t( _vm.i18nKeyAllItemsItem ))+" ")])],1):_vm._e(),(_vm.loading)?_c('DataLoading',{attrs:{"width":2}}):_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,staticClass:"gst-drop-down__list-item",class:{ 'selected': _vm.value && _vm.value[_vm.valueProp] === item[_vm.valueProp] },on:{"click":function($event){return _vm.select( item )}}},[_c('v-list-item-title',[_c('div',[_vm._v(_vm._s(_vm.renderItemFn( item )))])])],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }