<template>
    <div class="mt-6 px-4 px-md-0">
        <PolicyPrismic :class="{ 'pt-1': !hideBanner, 'pt-4': hideBanner}" />
    </div>
</template>
<script>
    import StaticPageMixin from '@core/mixins/StaticPageMixin';
    import ViewDocumentTitleMixin from '@tenant/app/mixins/ViewDocumentTitleMixin';
    import PolicyPrismic from '@tenants/ticketmaster/app/static/Policy/PolicyPrismic.vue';


    export default {
        name: 'Policy',
        components: {
            PolicyPrismic,
        },
        mixins: [ 
            StaticPageMixin,
            ViewDocumentTitleMixin
        ],
        i18nOptions: {
            namespaces: '_common',
            keyPrefix: 'pages.policy'
        },
        props: {
            'hideBanner': {
                type: Boolean,
                default: false
            },
        },
        mounted( ) {
            this.updateDocumentTitle( this.$t( 'main:viewsTitle.thePolicy' ) );
        } 
    };
</script>
