<template>
    <div class="gst-policy">
        <DataLoading v-if="loading" class="mt-5 mb-5 pt-5 pb-5" />
        <template v-else>
            <PrismicRichText :field="content.data.title" class="gst-policy__title" />
            <PrismicRichText :field="content.data.subtitle" class="gst-policy__subtitle" />
            <span> {{ $t('countryDropdownLabel') }} </span>
            <DropDown
                :value="selectedCountry"
                :clearable="false"
                :items="countryCodesList"
                @input="onInputDo" />
            <div class="slicezone">
                <SliceZone
                    v-if="!loading"
                    :slices="content.data.body"
                    :context="slicesContext"
                    :components="{
                        simple_content: SimpleContent,
                    }" />
            </div>
        </template>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import DropDown from '@core/shared/components/input/DropDown';
    import DataLoading from '@core/shared/components/loading/DataLoading.vue';
    import { LogService } from '@core/services';
    import {  addCounterOnSlices } from '@tenant/app/utils/prismicUtils';
    import SimpleContent from '@tenants/ticketmaster/app/static/slices/SimpleContent.vue';
    import { redirectToHome  } from '@tenant/app/utils/tenantUrlslUtils';
    import { validateCountryCode } from '@tenant/app/utils/policyCountriesUtils';
    import policyCountries from '../../utils/constants/policyCountries';

    export default {
        name: 'PolicyPrismic',
        components: {
            DataLoading,
            DropDown
        },
        i18nOptions: {
            namespaces: '_common',
            keyPrefix: 'pages.policy'
        },
        data() {
            return {
                content: null,
                selectedCountry: policyCountries.OPTIONS[0],
                SimpleContent,
                loading:true
            };
        },
        computed: {
            ...mapGetters( {
                appLocale: 'appState/getLocale'
            } ),
            slicesContext( ) {
                return {
                    eventId: this.$route.params.id
                };
            },
            countryCodesList( ) {
                return policyCountries.OPTIONS;
            },
            countryCodeFromRoute() {
                const countryCode = this.$route.query.countryCode && this.$route.query.countryCode.toLowerCase();
                return countryCode && validateCountryCode( countryCode ) ? countryCode : policyCountries.DEFAULT_COUNTRY;
            }
        },
        watch: {
            countryCodeFromRoute: function ( newValue, oldValue ) {
                if ( newValue !== oldValue ) {
                    this.initByCountryCode( newValue );
                }
            }
        },
        methods: {
            initByCountryCode( countryCode ) {
                const country = this.countryCodesList.find( country => country.value === countryCode );
                if ( country?.uid ) {
                    this.selectedCountry = country;
                    this.getPolicyContent( );
                }
            },
            onInputDo( item ) {
                if ( item.uid ) {
                    this.$router.replace( {
                        ...this.$route, 
                        query: {
                            ...this.$route.query,
                            countryCode: item.value
                        }
                    } );
                } else if ( item.url ) {
                    window.open( item.url, '_blank' );
                }
            },
            async getPolicyContent( ) {
                this.loading = true;
                this.content = await this.getContent( this.appLocale );
                if ( !this.content ) {
                    // fallback to master language
                    this.content = await this.getContent( );
                }
                if ( this.content ) {
                    this.content.data.body = addCounterOnSlices( this.content );
                    this.$nextTick( ( ) => {
                        this.scrollToHashAfterLoading( );
                    } );
                    this.loading = false;
                } else {
                    redirectToHome( );
                }
            },
            async getContent( lang ) {
                const config = lang ? { lang } : { };
                const documentUID = this.selectedCountry.uid || 'ticketmaster_policy_us';

                try {
                    return await this.$prismic.client.getByUID(
                        'static_content',
                        documentUID,
                        config
                    );
                } catch( e ) {
                    LogService.log( e.message );
                }
            },
            scrollToHashAfterLoading( ) {
                const hash = window.location.hash;
                if ( hash ) {
                    this.$router.replace( { ...this.$route, hash: '' } );
                    this.$router.replace( { ...this.$route, hash } );
                }
            },
        },
        async mounted( ) {
            this.initByCountryCode( this.countryCodeFromRoute );
        }
    };
</script>

<style lang="scss">
@import "@scssMixins";
@import "@scssVariables";

$slice-light-background: #FFFFFF;
$slice-light-section-background: #FFFFFF;
$slice-light-section-secondary-background: #F6F6F6;
$slice-light-text-color: #121212;
$slice-light-text-color-2: #646464;
$slice-light-link-color: #024DDF;
$slice-light-button-cta-disabled-background: #646464;
$slice-light-button-cta-disabled-color: #FFFFFF;
$slice-light-button-cta-color: #FFFFFF;
$slice-light-button-cta-background: #024DDF;
$slice-light-button-background: #646464;
$slice-light-button-color: #121212;
$slice-light-button-hover-background: #646464;
$slice-light-button-hover-color: #121212;
$slice-light-button-active-background: #646464;
$slice-light-button-active-color: #121212;
$slice-light-border: #646464;
$slice-light-dropdown-border: #EBEBEB;
$slice-light-dropdown-row-hover: #F6F6F6;
$slice-light-dropdown-text-color: #646464;
$slice-light-image-border: #FFFFFF;
$slice-light-location-vertical-line: #EBEBEB;
$slice-light-modal-button-background: #D6D6D6;

$slice-dark-background: #000000;
$slice-dark-section-background: #000000;
$slice-dark-text-color: #FFFFFF;
$slice-dark-link-color: #024DDF;
$slice-dark-button-background: #646464;
$slice-dark-button-color: #121212;
$slice-dark-button-hover-background: #646464;
$slice-dark-button-hover-color: #121212;
$slice-dark-button-active-background: #646464;
$slice-dark-button-active-color: #121212;
$slice-dark-border: #121212;

.gst-policy {
    margin: auto !important;
    font-family: "Averta Std", sans-serif !important;
    max-width: 959px;

    section[data-gst-nav=true] {
        scroll-margin-top: 65px;
    }

    .gst-policy__subtitle {
        p {
            color: theme-color( 'tertiary' ) !important;
            font-size: font-size( 'xxs' );
        }
    }

    .gst-slice {
        display: flex;
        width: 100%;

        .gst-slice__content {
            display: flex;
            flex-direction: column;
        }

        .gst-slice__content--width-100 {
            width: 100%;
            padding-right: unset;
            padding-left: unset;
            max-width: unset;
        }

        .gst-slice__content--height-100 {
            height: 100%;
            padding-top: unset;
            padding-bottom: unset;
        }

        h1 {
            line-height: 48px;
            font-size: 40px;
            font-weight: 800;
            font-style: normal;
        }

        h2 {
            line-height: 24px;
            padding-bottom: 8px;
            margin-bottom: 32px;
            color: $slice-light-text-color;
            font-size: 20px;
            font-weight: 700;
            font-style: normal;
            border-bottom: 4px solid transparent;
        }

        h3 {
            line-height: 21px;
            color: $slice-light-text-color;
            font-size: 18px;
            font-weight: 600;
            font-style: normal;
        }

        h4 {
            line-height: 17px;
            color: $slice-light-text-color;
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
        }

        h5 {
            line-height: 17px;
            color: $slice-light-text-color-2;
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
        }

        h6 {
            line-height: 21px;
            margin-bottom: 16px !important;
            color: rgba( 0, 0, 0, 0.87 );
            font-size: 16px;
            font-weight: 700;
            font-style: normal;
        }

        p {
            line-height: 21px;
            margin-bottom: 0;
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            margin-block-end: 14px;
        }

        a {
            color: $slice-light-link-color;
            font-size: 14px;
            font-weight: 400;
        }

        strong {
            a {
                font-weight: bold;
            }
        }
    }
}
</style>